.appbar{
    position: relative;
}

.flex{
    flex:1;
}

.content{
    padding: 0 !important;
}

.content iframe{
    height: 92vh;
    width: 100%;
    /* margin-top:7vh; */
}

.modalMap{
    height:92vh;
}
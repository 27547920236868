@font-face{
  font-family: 'UniNeue';
  src: url('./static/fonts/UniNeueRegular.otf');
}


.App {
    text-align: center;
    font-family: 'UniNeue' !important;
    background-color: #0b142eff; 
  }
  
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
    height: 5vmin;
    clear: none;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  

  .container{
    background-color: #0b142eff !important;
    background-image: linear-gradient(to bottom, rgba(11,20,46,1), rgba(11,20,46,0));
  }
  
  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }
  
  #modalmap{
    height:90vh;
  }
  
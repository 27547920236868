@font-face{
    font-family: 'UniNeue';
    src: url('../../../static/fonts/UniNeueRegular.otf');
  }
  

.dashblock{
    height: 30vh;
    cursor: pointer;
    /* background-color: #b0d89eff !important; */
    /* border: 1pt solid #b0d89eff !important; */
    
}

/* .dashblock .header{
    font-family: 'UniNeue' !important;
    font-weight: bold;
    font-size: 12px;
    color:#b0d89eff !important;
    position: relative;
} */

/* .dashblock .header:before {
    content: '';
    position: absolute;
    top: 0; right: 0;
    border-bottom: 65px solid #b0d89eff;
    border-left: 65px solid #0c1529ff;
    width: 0;
} */

.dashblock .dashmedia{
    height: 10vh;
}